.logo {
  object-fit: fill;
  max-width: 100%;
  max-height: 47px !important;
  display: flex;
  width: auto;
  height: auto;
}

.logoDiv {
  display: flex;
  max-width: 30%;
  text-align: left;
  max-height: 47px;
  margin-right: 3%;
}

.details {
  text-align: left;
  display: flex;
  max-width: 70%;
  max-height: 47px;
  margin-right: 3%;
}

.HeaderContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 77px !important;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: none !important;
}

#language-dropdown-component {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-close-button {
  margin-left: auto;
  display: flex;
}

.header-back-button {
  margin-right: 0.3rem;
}

.header-close-button-styling {
  z-index: 0 !important;
  border-radius: 50% !important;
  min-width: fit-content !important;
}
